<template>
  <FocusTrap>
    <div class="card">
      <div class="card-body">
        <div id="formio"></div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import {userService} from '@/store/auth-header.js'
export default {
  name: 'TourPlanForm',
  data() {
    return {
      medical: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}'),
      areas : [],
    }
  },
  component: {

  },
  props: {
    mymedical: {
      type: Object,
      required: true,
      default: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}')
    }
  },
  beforeMount () {
    this.medical = this.mymedical; // save props data to itself's data and deal with tms
    // this.medical.is_crdr = (this.medical.opening_balance > 0 ? 1 : -1);
    // this.medical.opening_balance = Math.abs(this.medical.opening_balance);
  },
  created () {
    this.$data.medical = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}')
  },
  mounted () {
    Formio.icons = 'fontawesome';
    Formio.createForm(document.getElementById('formio'), 'https://examples.form.io/w4', {
      zoom: '-20'
    });
  },
  methods: {
    closeWindow(){
      this.medical = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","address":"","nature":"","area_id":1,"regno":"","tel":"","email":"","owner":"","gstintype":1,"gstin":"","state_cd":33,"dob":"","dow":"","last_visit_date":"","owner_mobile":"","incharge_name":"","incharge_mobile":"","data":""}');
      this.$emit("medical_window_closed");
    },
    loadAreas () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };
      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.areas = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/areas`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data) ){
            self.$data.areas = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      })

    },
    saveDocument(){

      const self = this;

      self.$data.medical.type = parseInt(self.$data.medical.type);
      if(self.$data.medical.name.toString().length< 5){
        alert("Invalid Name");
        return;
      }else if( parseInt(self.$data.medical.state_cd) < 1 ){
        alert("Invalid State selected");
        return;
      }

      self.$data.medical.state_cd = parseInt(self.$data.medical.state_cd);
      self.$data.medical.opening_balance =  $('#cmbcrdr').val() *  Math.abs(parseFloat(self.$data.medical.opening_balance));

      const requestOptions = {
        method:  (self.$data.medical.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.medical)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/medical`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer: 3000} );
          self.$emit('medical_saved', resp.data);
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
